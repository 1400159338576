body {
  margin: 0;
  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: scroll;

}

body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

html {
  scroll-behavior: smooth;
}


@tailwind base;
@tailwind components;
@tailwind utilities;